@import "../../assets/styles/scss/variables";

#page-header.concert-header {
    display: grid;
    grid-template-columns: calc(40% - 2.36rem) calc(60% - 2.36rem);
    gap: 4.72rem;
    padding: 3.75rem 0 0;

    .left, .right {
        height: 100%;
    }

    .title h1{
        font-size: clamp(2.5rem, 5.6vw, 5.6rem);
        line-height: clamp(2.5rem, 5.6vw, 5.6rem);
    }

    .right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        gap: 2.5rem;
        
        .infos {
            display: flex;
            flex-direction: column;
            gap: 2.5rem;
            width: 100%;

            .date-container {
                display: flex;
                justify-content: flex-start;
            }
        
            .concert-datetime {
                display: flex;
                gap: 0.75rem;
                flex-direction: column;
    
                .concert-date {
                    min-width: 270px;
                    width: fit-content;
                    &::before {
                        content: '\f133';
                    }
                }
                
                .concert-hour {
                    width: fit-content;
                    &::before {
                        content: '\f017';
                    }
                }
            }

            @media (max-width:767px) {
                .date-container {
                    flex-direction: column;
                    gap: 4px;
                }

                .concert-datetime {
                    .concert-date, .concert-hour {
                        width: 100%;
                    }
                }
            }
            
            .concert-localisation {
                width: 100%;
                &::before {
                    content: '\f3c5';
                }
            }
        }
    }

    .left {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        height: fit-content;
        overflow: hidden;
        border-radius: $rounded;

        img {
            height: 100%;
            min-height: 100%;
            max-height: 100%;
            width: 100%;
            max-width: 100%;
            min-width: 100%;
            object-fit: contain;
            border-radius: $rounded;
        }
    }

    @media (max-width:991px) {
        display: flex;
        flex-direction: column;

        .right {
            align-items: flex-start;
        }
    }

    @media (max-width:767px) {
        padding: 0.75rem 0 0;
        gap: 2.72rem;
        
        .right {
            gap: 1.5rem;
        }
    }
}