@import "../../assets/styles/scss/variables";

.schedule-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2.83rem;
    gap: 1.33rem;
    text-align: center;
    background-image: url(../../assets/images/hh-schedule.svg);
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: 20%;

    h3 {
        font-size: clamp(2rem, 4vw, 3rem);
    }

    a {
        width: fit-content;
    }

    @media(max-width:767px) {
        background-image: none;
    }
}