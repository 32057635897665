@import "../../assets/styles/scss/variables";

.audio-block {
    background-color: $color-white;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.33rem;


    .audio-player {
        display: flex;
        margin-top: 10px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        i {
            color: $color-red;
        }

        .playPauseBtn {
            width: 40px;
            background-color: transparent;
        }

        .progress {
            display: flex;
            align-items: center;
            width: 85%;
            color: $color-gray;
            justify-content: space-between;

            .progressBar::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 10px;
                height: 10px;
                background-color: $color-red;
                border-radius: 50%;
                cursor: pointer;
            }

            .progressBar::-moz-range-thumb {
                width: 10px;
                height: 10px;
                background-color: $color-red;
                border-radius: 50%;
                cursor: pointer;
            }
        }

        .volume {
            display: flex;
            align-items: center;
            width: fit-content;

            .volumeBar {
                width: 60px;
            }
        }

        .progressBar {
            margin-right: 20px;
            border-radius: 2rem;
        }

        .totalTime {
            margin-right: 10px;
        }

        .progressBar,
        .volumeBar {
            width: 90%;
            color: transparent;
        }

        input[type="range" i] {
            appearance: none;
            cursor: pointer;
            border-radius: 2rem;
            overflow: hidden;
        }

        .progressBar::-webkit-slider-thumb,
        .volumeBar::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 10px;
            height: 10px;
            background-color: $color-red;
            border-radius: 50%;
            cursor: pointer;
        }

        .progressBar::-moz-range-thumb,
        .volumeBar::-moz-range-thumb {
            width: 10px;
            height: 10px;
            background-color: $color-red;
            border-radius: 50%;
            cursor: pointer;
        }

        .progressBar::-webkit-slider-runnable-track,
        .volumeBar::-webkit-slider-runnable-track {
            background-color: $color-red-light !important;
            height: 10px;
        }

        .progressBar::-moz-range-track,
        .volumeBar::-moz-range-track {
            background-color: $color-red !important;
            height: 10px;
        }
    }

    .control-btn {
        padding: 10px 10px 10px 0;
        font-size: 16px;
        cursor: pointer;
    }

}