//
// Base Styles
// --------------------------------------------------

html,
body {
  font-family: $font-base;
  font-size: $regular; //18px
  line-height: $text-base-line-height;
  font-weight: normal;
  color: $color-primary;
  scroll-behavior: smooth;

  @media (max-width:767px) {
    font-size: $small; //16px
    line-height: $text-base-line-height;
  }
}

body {
  overflow-x: hidden !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

.section-red {
  background-color: $color-red-light;
}

.container {
  width: 75vw;
  max-width: 1224px;
  margin: 0 auto;

  @media (max-width:1224px) {
    padding: 0 $medium;
  }

  @media (max-width:1199px) {
    max-width: 90%;
    width: 90%;
  }

  @media (max-width:991px) {
    max-width: 95%;
    width: 95%;
  }

  @media (max-width:767px) {
    max-width: 100%;
    width: 100%;
  }

  .small-container {
    width: 100%;
    max-width: 83%;
    margin: 0 auto;

    @media (max-width:991px) {
      max-width: 90%;
    }

    @media (max-width:767px) {
      max-width: 100%;
    }
  }
}

.small-header {
  width: 100%;
  max-width: 83%;
  margin: 0 auto;
}

.is-hidden,
.hidden {
  display: none !important;
}

.xsmall {
  font-size: $xsmall;
  line-height: normal;
  font-weight: normal;
}

.small {
  font-size: $small;
  line-height: normal;
  font-weight: normal;
}

p,
.regular {
  font-size: $regular;
  line-height: $text-base-line-height;
  font-weight: normal;

  @media (max-width:767px) {
    font-size: $small;
  }
}

p {
  margin-bottom: .8rem;

  &:last-child {
    margin-bottom: 0;
  }

  strong {
    font-weight: 500;
  }

  &.xsmall {
    font-size: $xsmall;
  }
}

h6,
.medium {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.11111rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: -0.01111rem;
}

h5,
.big {
  font-size: $big;
  line-height: normal;
  font-weight: normal;

  @media (max-width:767px) {
    font-size: 1.4rem;
    line-height: normal;
  }
}

h4,
.large {
  font-size: $large;
  line-height: normal;
  font-weight: normal;

  @media (max-width:1199px) {
    font-size: 1.7rem;
    line-height: 1.8rem;
  }

  @media (max-width:991px) {
    font-size: 1.6rem;
    line-height: 1.7rem;
  }

  @media (max-width:767px) {}
}

h3,
.massive {
  font-size: $massive;
  line-height: normal;
  font-weight: normal;

  @media (max-width:1199px) {
    font-size: 2.1rem;
    line-height: 1.7rem;
  }

  @media (max-width:991px) {
    font-size: 2rem;
    line-height: 1.6rem;
  }

  @media (max-width:767px) {}
}

h2,
.huge {
  font-size: $huge;
  line-height: 5.44444rem;
  font-weight: normal;
  text-transform: initial;

  @media (max-width:1199px) {
    font-size: 4.5rem;
    line-height: 3.5rem;
  }

  @media (max-width:991px) {
    font-size: 3.5rem;
    line-height: 3rem;
  }

  @media (max-width:767px) {
    font-size: 2rem;
    line-height: 2rem;}
}

h1,
.gigantic {
  font-size: clamp(2.5rem, 7.6vw, 7.5rem);
  line-height: clamp(2.5rem, 7.6vw, 7.5rem);
  font-weight: normal;
}

.rounded {
  border-radius: $rounded;
}

.box-shadow {
  box-shadow: $box-shadow;
  border: solid 1px #eee;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: $shadow-hover;
  }
}

.shadow-no-hover {
  box-shadow: $box-shadow;
  border: solid 1px #eee;
}

.img-container {
  width: 100%;
  object-fit: cover;
  object-position: center;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.info {
  color: $color-gray;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .44rem;

  &:before {
    font-family: $fontawesome;
    background-color: $color-gray-lighter;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    min-width: 2.5rem;
    height: 2.5rem;
    aspect-ratio: 1;
  }
}

.tag {
  width: fit-content;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0 1.12rem;
  background-color: $color-gray-lighter;
  border-radius: 3.125rem;
  line-height: 2rem;
}

.btn-primary {
  font-size: $regular;
  line-height: normal;
  font-weight: 500;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  color: white;
  background-color: $color-red;
  border: none;
  border-radius: 0.63rem;
  padding: .5rem 1.88rem;
  transition: all .2s linear;

  &.has-icon {
    aspect-ratio: 1;
    padding: 0.56rem 0.76rem;
  }

  &:hover {
    background-color: $color-red-dark;
    color: white;
  }

  &.invert,
  &.reverse {
    color: $color-red;
    border: 2px solid $color-red;
    background-color: white;

    &:hover {
      background-color: $color-red;
      color: white;
    }
  }
}

.btn-secondary {
  padding: 0 0.2rem 0.2rem 0;
  transition: all 0.3s ease;
  position: relative;
  line-height: 1.5rem;

  &:before {
    padding: 0 0.62rem 0 0;
    font-family: $fontawesome;
    content: '\f054';
    transition: all 0.3s ease;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    border-radius: 5px;
    height: 0.05em;
    bottom: 0;
    left: 0;
    transform-origin: bottom right;
    background: $color-red-dark;
    transition: transform .25s ease-out;
  }

  &.download {
    &:before {
      padding: 0 0.62rem 0 0;
      font-family: $fontawesome;
      content: '\f33d';
      transition: all 0.3s ease;
    }

    &:hover {
      &:before {
        padding: 0 0.62rem 0 0;
      }
    }

  }

  &.linkedin {
    &:before {
      padding: 0 0.62rem 0 0;
      font-family: "Font Awesome 6 Brands";
      content: '\f08c';
      transition: all 0.3s ease;
    }

    &:hover {
      &:before {
        padding: 0 0.62rem 0 0;
      }
    }
  }

  &.extern {
    &:before {
      padding: 0 0.62rem 0 0;
      font-family: $fontawesome;
      content: '\f08e';
      transition: all 0.3s ease;
    }

    &:hover {
      &:before {
        padding: 0 0.62rem 0 0;
      }
    }
  }

  &.phone {
    &:before {
      padding: 0 0.62rem 0 0;
      font-family: $fontawesome;
      content: '\f095';
      transition: all 0.3s ease;
    }

    &:hover {
      &:before {
        padding: 0 0.62rem 0 0;
      }
    }
  }

  &.mail {
    &:before {
      padding: 0 0.62rem 0 0;
      font-family: $fontawesome;
      content: '\f0e0';
      transition: all 0.3s ease;
    }

    &:hover {
      &:before {
        padding: 0 0.62rem 0 0;
      }
    }
  }

  &:hover {
    color: $color-red-dark;

    &:before {
      padding: 0 0.42rem 0 0.20rem;
    }

    &:after {
      transform: scaleX(1);
      transform-origin: bottom left;
      transition-duration: .3s;
    }
  }

}

.load-more {
  display: flex;
  justify-content: center;
  padding: 3.56rem 0 0;

  #load-more-button {
    &:before {
      content: "";
      padding: 0;
    }
  }
}

.btn-back {
  font-size: $regular;
  line-height: normal;
  font-weight: 500;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  color: $color-gray;
  background-color: $color-gray-lighter;
  border: none;
  border-radius: 0.63rem;
  padding: .5rem 1.88rem;
  margin: 2rem 0 -1.5rem;
  transition: all .2s linear;
  z-index: 10;
  position: relative;
  padding: 0.8rem 1.88rem;

  &:before {
    padding: 0 0.62rem 0 0;
    margin: 0;
    font-family: $fontawesome;
    content: '\f053';
    transition: all 0.3s ease;
  }

  &:hover {
    color: $color-black;

    &:before {
      margin: 0 0.62rem 0 -0.62rem;
    }
  }
}

.concert-link-ext {
  color: $color-red;

  &:before {
    margin: 0;
    font-family: $fontawesome;
    content: '\f35d';
    transition: all 0.3s ease;
  }

  &:hover {
    color: $color-red-dark !important;
  }
}

#post-share {
  display: flex;
  justify-content: flex-end;

  div {
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 0.9rem 1.1rem;
    background-color: $color-gray-lighter;
    gap: 1.1rem;

    p {
      margin: 0;
      font-size: 0.88889rem;
      color: $color-red;
      font-style: normal;
      font-weight: 500;
      line-height: 1.77778rem;
      letter-spacing: -0.00889rem;
    }
  }
}

.bg-gray-lighter {
  background-color: $color-gray-lighter;
}

.bg-red-light {
  background-color: $color-red-light;
}

.bg-white {
  background-color: $color-white;
}

.filters {
  display: flex;
  max-width: 100%;
  margin: 0;
  padding: 2.22rem 0;
  align-items: center;
  list-style: none;

  li {
    color: $color-black;
    opacity: .3;
    transition: all .2s linear;
    cursor: pointer;
    padding: 0.33333rem 1.66667rem;
    font-family: Montserrat;
    font-size: 0.88889rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.00889rem;      
    border-radius: 5rem;
    border: 1px solid $color-black;

    &:hover,
    &.is-active {
      opacity: 1;
      background-color: rgba(224, 8, 28, 0.30);
      border: 1px solid rgba(224, 8, 28, 0);
    }
  }

  @media (max-width:767px) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 0.7rem;
  }
}

.filter-title {
  font-family: Montserrat;
  font-size: 0.77778rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.00778rem;
  margin-right: 1.11rem;
}

.filters-list {
  font-size: $big;
  line-height: normal;
  font-family: $font-title;
  font-weight: 500;
  padding: 0;
  margin: 0;
  list-style: none;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.11rem;
  display: none;
  visibility: hidden;
  width: fit-content;

  &.is-showed {
    display: flex;
    visibility: visible;
    flex-wrap: wrap;
    row-gap: 1.2rem;

    @media (max-width:767px) {
      row-gap: 0.7rem;
    }
  }

  li {
    cursor: pointer;
    transition: all .2s linear;
    opacity: .5;

    &.is-active {
      opacity: 1;
    }
  }

  &:hover {

    li {
      opacity: .5;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.filters {
  .filter {
    opacity: .5;

    &.is-active,
    &:hover {
      opacity: 1;
      color: $color-black;
    }
  }
}

.outdated {
  color: #8b8b8b99;

  .info {
    color: #8b8b8b99;
    width: fit-content;
  }
}