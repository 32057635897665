@import "../../assets/styles/scss/variables";

.socials {
    display: flex;
    gap: 0.94rem;

    li {
        a {
            color: $color-red !important;
        }
        transform-origin: center center;
        transition: transform .3s linear;
        &:hover {
            transform: scale(1.1);
            a {
                i {
                    color: $color-red-dark !important;
                }
            }
        }
    }
}