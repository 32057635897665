@import "../../assets/styles/scss/variables";

.concert-block {
    padding: 1.33rem;

    .content {
        display: grid;
        grid-template-columns: calc(30% - 1.555rem) calc(70% - 1.555rem);
        gap: 3.11rem;

        @media (max-width:1199px) {
            grid-template-columns: calc(40% - 1.555rem) calc(60% - 1.555rem);
        }

        @media (max-width:767px) {
            display: flex;
            flex-direction: column;

            .left {
                height: 46vh;
                max-height: 45vh;
            }
        }

        .left {
            overflow: hidden;

            img {
                object-fit: contain;
                border-radius: $rounded;
                display: flex;
                align-items: center;
            }
        }

        .right {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 0.5rem;
            justify-content: space-between;

            .infos {
                display: flex;
                flex-direction: column;
                gap: 2.5rem;
                width: 100%;

                .concert-datetime {
                    display: flex;
                    gap: 0.75rem;
                    flex-wrap: wrap;

                    .concert-date {
                        width: fit-content;

                        &::before {
                            content: '\f133';
                        }
                    }

                    .concert-hour {
                        width: fit-content;

                        &::before {
                            content: '\f017';
                        }
                    }

                    .concert-localisation {
                        width: 100%;

                        &::before {
                            content: '\f3c5';
                        }
                    }
                }
            }
        }

        .tag {
            float: right;
            background-color: $color-red-light;
        }

        .tag.passed {
            color: $color-gray;
            background-color: $color-gray-lighter;
        }

        .actuality-content {
            width: 80%;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
        }
    }
}