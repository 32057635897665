@import "../../assets/styles/scss/variables";

.video-block {
    background-color: $color-white;
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .video-content{
        aspect-ratio: 16 / 9;
    }

    iframe {
        max-width: 100%;
        width: 100%;
        height: 100%;
    }

    .content {
        display: flex;
        flex-direction: column;
        padding: 1.1rem;
        row-gap: 1rem;

        .content-more {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: $color-gray;

            .extern {
                transition: all 0.3s ease;

                &:hover {
                    i {
                        color: $color-red-dark;
                    }
                }
            }
        }

        @media (max-width:767px) {
            row-gap: 1rem;
        }
    }

}