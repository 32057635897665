@import "../../assets/styles/scss/variables";

.actuality-bloc {
    background-color: $color-white;
    padding: 1.5rem;
    .content {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .tag, .actuality-link{
            float: right;
        }

        .tag {
            color: $color-primary;
        }

        .actuality-content{
            width: 80%;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
        }
    }

    &:hover {
        .content {
            .tag {
                color: $color-primary;
            }
        }
    }
} 