@import "../../assets/styles/scss/variables";

.link-bloc {
    background-color: $color-white;
    padding: 1.5rem;
    display: grid;
    grid-template-columns: calc(30% - 0.665rem) calc(70% - 0.665rem);
    gap: 1.33rem;
    .content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 0.5rem;

        .tag, .actuality-link{
            float: right;
        }

        .tag {
            color: $color-primary;
        }

        .publication-content{
            color: $color-primary;
            width: 80%;
            display: flex;
            flex-direction: column;
            gap: .5rem;
        }

        .publication-link-container {
            width: fit-content;
            display: flex;
            align-items: center;
            gap: .75rem;
        }

        .publication-link {
            width: fit-content;
            display: flex;
            align-items: center;
            gap: .75rem;
        }
    }
} 

.link-bloc:not(.left) {
    display: flex;
    .content {
        width: 100%;
    }
} 