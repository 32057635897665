@import "../../assets/styles/scss/variables";

#page-header.standard-header {
    .content {
        display: flex;
        justify-content: space-between;
        padding: 5.56rem 0;

        @media (max-width:767px) {
            overflow: hidden;
            padding: 0.56rem 0 3.56rem 0;
        }
    }
    img {
        position: absolute;
        top: 30px;
        right: -30px;

        @media (max-width:991px) {
            right: 5px;
            width: 28%;
        }

        @media (max-width:767px) {
            top: 0;
            width: 23%;
        }
    }
}