//
// Footer Styles
// --------------------------------------------------

#footer {
	background-color: $color-white;
	font-size: 14px;
	padding: 4.78rem 0 1rem; 

	#footer-top {
		display: grid;
		grid-template-columns: repeat(2, 30% 70%);
		.left {
			display: flex;
			flex-direction: column;
			gap: 1.33rem;
			border-right: solid 1px $color-gray-lighter;

			.footer-logo {
				img {
					width: 45%;
				}
			}

			.footer-contact {
				width: fit-content;
				padding: 0.3rem;
				border-bottom: solid 1px $color-red;
				transition: all 0.3s ease;
				font-weight: 500;
				&:hover {
					border-bottom: solid 1px transparent;
				}
			}
		}
		.right {    
			display: grid;
			grid-template-columns: calc(75% - 2.11rem) calc(25% - 2.11rem);
			gap: 4.22rem;
			padding: 0 0 0 4.44rem;

			.xsmall {
				text-transform: uppercase;
			}

			.footer-partners {
				.partners-grid {
					display: grid;
					grid-template-columns: repeat(3, 1fr);
					grid-template-rows: max(5.5rem);
					padding: 1.22rem 0 0.56rem;
					gap: 1.33rem;
					.partner {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						img {
							width: 100%;
							max-height: 5.5rem;
						}
					}
				}
			}
			.footer-partners-media {
				.partners-grid {
					display: grid;
					grid-template-columns: repeat(1, 1fr);
					grid-template-rows: max(5.5rem);
					padding: 1.22rem 0 0.56rem;
					.partner {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						img {
							width: 100%;
							max-height: 5.5rem;
						}
					}
				}
			}
		}
		p {
			color: $color-gray;
		}

        @media (max-width:991px) {
			.right {
				display: flex;
				flex-direction: column;
				.footer-partners-media {
					.partners-grid {
						grid-template-columns: repeat(3, 1fr);
					}
				}
			}
        }

        @media (max-width:767px) {    
			display: flex;
			flex-direction: column;
			.left {
				border-right: 0px;
				.footer-logo {
					img {
						width: 8.95rem;
					}
				}
			}
			.right {
				padding: 5rem 0 0;    
				gap: 2.22rem;
			}
        }
	}

	ul {
		padding: 0;
		margin: 0;
		list-style: none;
		li {
			a {
				color: $color-gray;
				&:hover {
					color: $color-primary;
				}
			}
		}
	}

	#footer-bottom {
		margin-top: 5rem;
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: $color-gray;
		a {
			color: $color-gray;
		}

		@media (max-width:767px) {    
			flex-direction: column;
			align-items: flex-start;
			gap: .5rem;
			padding-bottom: 1rem;
		}
	}

	.powered {
		position: relative;
		color: inherit;
		&:hover {
			color: $color-black;
			text-decoration: none;
			#logo-alt-footer-trois {
				fill: #FFD94A;
				transform: scale(1.2);
			}
			#logo-alt-footer-deux {
				fill: #FFD94A;
				transform: scale(1.2);
			}
			#logo-alt-footer-un {
				fill: #FFD94A;
				transform: scale(1.2);
			}
			&:after {
				width: 100px;
			}
		}
		svg {
			overflow: visible;
			width: 50px;
			height: 24px;
			margin: 0 0 0 5px;
			position: relative;
			top: -2px;
			vertical-align: middle;
			width: 34px;
			#logo-alt-footer-trois {
				fill: $color-gray-darker;
				transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
				transform: scale(1);
				transform-origin: center;
			}
			#logo-alt-footer-deux {
				fill: $color-gray-darker;
				transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
				transform: scale(1);
				transform-origin: center;
			}
			#logo-alt-footer-un {
				fill: $color-gray-darker;
				transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.4s;
				transform: scale(1);
				transform-origin: center;
			}
		}
		&:after {
			display: block;
			content: '';
			position: absolute;
			bottom: -2px;
			left: 83px;
			width: 0px;
			height: 2px;
			background-color: #FFD94A;
			transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
		}
	}
}
