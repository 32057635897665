@import "../../assets/styles/scss/variables";

.report-block {

    display: flex;
    flex-direction: row;
    padding: 1.5rem;
    justify-content: space-between;
    gap: 3rem;

    .tag {
        color: $color-primary;
    }

    .report-info {
        display: flex;
        flex-direction: column;
        gap: 0.28rem;
        .report-description {
            color: $color-gray;   
        }
    }

    .report-link {
        display: flex;
        gap: 0.75rem;
        align-items: center;
        width: fit-content;
    }

    &:hover {
        .tag {
            color: $color-primary;
        }
    }

} 