@import "../../assets/styles/scss/variables";

.publication-bloc {
    background-color: $color-white;
    padding: 1.5rem;
    display: grid;
    grid-template-columns: calc(35% - 0.665rem) calc(65% - 0.665rem);
    gap: 1.33rem;
    .left {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            height: auto;
        }
    }
    .content {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        gap: 0.5rem;

        .tag {
            color: $color-primary;
        }

        .tag, .actuality-link{
            float: right;
        }
        .publication-content{
            color: $color-gray;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
        }
    }
} 