@import "../../assets/styles/scss/variables";

.members-block {
    display: flex;
    flex-direction: column;
    gap: 0.89rem;

    .member-name {
        h4 {
            font-size: 1.5rem;
            letter-spacing: -0.01222rem;
        }
    }

    .img-container {
        height: auto;
        aspect-ratio: 1;
        width: 100%;
        overflow: hidden;
    }

    .member-img {
        background-color: $color-white;

        img {
            height: 100%;
            width: 100%;
            aspect-ratio: 1;
            object-fit: cover;
            object-position: center;
        }
    }

    .member-function {
        font-size: 1.2rem;
        letter-spacing: -0.00778rem;
        color: $color-gray;
        padding-top: 10px;
    }

    .member-description {
        font-size: 1rem;
        letter-spacing: -0.00778rem;
        color: $color-gray;
        padding-top: 10px;
    }

    .member-img:has(.alternative-img) {
        padding: 3rem;
        
        img {
            object-fit: contain;
        }
    }
}

.members-block {
    background-color: white;
    padding: 2rem;
}