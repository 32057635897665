//
// Template Styles
// --------------------------------------------------

#main {
    padding-top: 121px; // Header height
    z-index: 15;
    overflow: hidden;
}

#page-homepage {

    #homepage-about {
        margin-top: 11.11rem;
        padding-bottom: 11.11rem;
        background-image: url(../../assets/images/hh-about.svg);
        background-position: top right;
        background-repeat: no-repeat;
        background-size: 25%;

        .homepage-about-intro {
            display: grid;
            padding-bottom: 3.56rem;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 14.06rem;

            .left {
                padding: 5.56rem 0 0;

                h2 {
                    padding: 0 0 4.17rem;
                }

                .homepage-about-content {
                    padding: 0 0 1.33rem;
                    font-size: $medium;
                }
            }

            @media (max-width:1199px) {
                grid-column-gap: 4.06rem;
            }

            @media (max-width:991px) {
                grid-template-columns: 60% auto;
            }

            @media (max-width:767px) {
                grid-template-columns: repeat(1, 1fr);

                .left {
                    padding: 5.56rem 0 2rem;

                    h2 {
                        padding: 0 0 2.17rem;
                        width: 60%;
                    }
                }

                .right {
                    display: none;
                }
            }
        }

        .homepage-facts {
            .facts-slider {
                padding: 2.22rem 5.22rem;
                background-color: $color-white;

                .slide {
                    display: flex;
                    flex-direction: column;
                    gap: 0.67rem;
                    width: 40%;
                    padding: 0 4.335rem 2.22rem;
                }

                @media (max-width:1199px) {
                    padding: 2.22rem 3.22rem;

                    .slide {
                        padding: 0 2.5rem 2rem;
                    }
                }

                @media (max-width:767px) {
                    .slide {
                        padding: 0 .5rem 2rem;
                    }
                }
            }

            .fact-slide-content,
            h3 {
                width: 100%;
                text-align: center;
            }
        }

        @media (max-width:1199px) {
            margin-top: 5.55rem;
            padding-bottom: 5.55rem;
        }
    }

    #homepage-actuality {

        margin-top: 5.55rem;
        padding-bottom: 11.11rem;
        background-image: url(../../assets/images/h.svg);
        background-repeat: no-repeat;
        background-position: left bottom;

        @media (max-width:1199px) {
            padding-bottom: 5.55rem;
        }

        .btn-secondary {
            margin-bottom: -22px;
        }

        .home-actuality-intro {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-bottom: 2.5rem;
            gap: 40px;

            @media (max-width:1199px) {
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;

                .btn-secondary {
                    margin-bottom: 0;
                }
            }
        }

        .actuality-grid {
            display: flex;
            flex-direction: column;
            gap: 3.33rem;
        }

        .actuality-bloc {
            padding: 1.33rem 3.11rem;

            .content {
                .actuality-content {
                    width: 100%;
                }

                @media (max-width:1199px) {
                    gap: 2rem;
                }
            }
        }
    }
}

#page-concert {
    padding: 5.56rem 0 11.11rem;

    .schedule-grid {
        margin: 2rem 0 3.5rem;

        @media (max-width:767px) {
            margin: 0 0 2.5rem;
        }

    }

    @media (max-width:767px) {
        padding: 5.56rem 0;
    }

    .concert-grid {
        display: flex;
        flex-direction: column;
        gap: 3.56rem;
    }
}

#single-concert {
    .small-container {
        max-width: 70%;

        @media (max-width: 991px) {
            max-width: 90%;
        }

        @media (max-width: 767px) {
            max-width: 100%;
        }
    }

    #post-share {
        margin: 3rem 0;
    }

    .concert-passed {
        padding: 5.56rem 0;
        display: flex;
        flex-direction: column;
        gap: 7.56rem;

        .passed-title {
            padding: 0 0 2rem;
        }

        .concert-gallery {
            display: flex;
            flex-direction: column;

            .gallery {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-auto-flow: dense;
                gap: 1.33rem;

                @media (max-width: 767px) {
                    grid-template-columns: repeat(2, 1fr);
                }
            }

            .gallery img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

#page-header.single-concert-header {
    display: grid;
    grid-template-columns: calc(50% - 2.36rem) calc(50% - 2.36rem);
    gap: 4.72rem;
    padding: 3.75rem 0 0;

    .left,
    .right {
        height: 100%;
    }

    .title h1 {
        font-size: clamp(2.5rem, 5.6vw, 5.6rem);
        line-height: clamp(2.5rem, 5.6vw, 5.6rem);
    }

    .right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        gap: 1.33rem;

        .infos {
            display: flex;
            flex-direction: column;
            gap: 1.33rem;
            padding: 1.56rem 2rem;
            width: 100%;
            background-color: $color-gray-lighter;

            .info {
                font-size: 1.11111rem;
                font-weight: 500;
            }

            .date-container {
                display: flex;
                justify-content: space-between;
            }

            .concert-datetime {
                display: flex;
                gap: 0.75rem;
                flex-direction: column;

                .concert-date {
                    width: 55%;

                    &::before {
                        background-color: transparent;
                        content: '\f133';
                    }
                }

                .concert-hour {
                    width: 40%;

                    &::before {
                        background-color: transparent;
                        content: '\f017';
                    }
                }

                .concert-localisation {
                    width: 100%;

                    &::before {
                        background-color: transparent;
                        content: '\f3c5';
                    }
                }

                .concert-link-ext {
                    color: $color-red;

                    &::before {
                        background-color: transparent;
                    }
                }
            }

            @media (max-width:767px) {
                .date-container {
                    flex-direction: column;
                    gap: 4px;
                }

                .concert-datetime {

                    .concert-date,
                    .concert-hour {
                        width: 100%;
                    }
                }
            }
        }

        .btn-primary {
            width: 100%;
            padding: 0.8rem 1.88rem;
        }

        @media (max-width:767px) {
            .title {
                padding: 1rem 0;
            }
        }
    }

    .left {
        border: $rounded;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            height: 100%;
            min-height: 100%;
            max-height: 100%;
            width: 100%;
            max-width: 100%;
            min-width: 100%;
            object-fit: contain;
            border-radius: $rounded;
        }
    }

    @media (max-width:991px) {
        display: flex;
        flex-direction: column;

        .left {
            height: 45vh;
        }
    }
}

#single-concert,
#single-publication {
    .page-section {
        padding: 6.67rem 0 4.34rem;
        background-image: url(../../assets/images/concert-single-background.svg);
        background-position: left bottom;
        background-repeat: no-repeat;

        @media (max-width:1920px) {
            background-size: 10%;
        }

        @media (max-width:767px) {
            background-size: 25%;
        }
    }

    .single-subtitle {
        padding: 0 0 2.22rem;
    }

    .concert-program {
        padding: 1.33rem 0;
    }
}

#page-actualities {
    .page-section {
        padding: 0 0 11.11rem;

        @media (max-width:767px) {
            padding: 0 0 5.56rem;
        }
    }

    .actuality-grid {
        display: flex;
        flex-direction: column;
        gap: 3.33rem;
    }

    .actuality-bloc {
        padding: 1.33rem 3.11rem;

        .content {
            .actuality-content {
                width: 100%;
            }

            @media (max-width:1199px) {
                gap: 2rem;
            }
        }
    }
}

#page-header.about-header {
    padding: 5.56rem 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12.89rem;

    .left {
        display: flex;
        flex-direction: column;

        .title {
            padding: 0 0 2.72rem;
        }

        .introduction {
            font-size: 1.25rem;
            line-height: 1.77778rem
        }

        .about-file {
            padding: 1.33rem 0 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            ul {
                list-style-type: none;
                margin: 0;
                padding: 0;
            }

            li {
                border-bottom: solid 1px $color-gray-lighter;
                padding: 1rem 0;
            }

            li:last-child {
                border-bottom: 0;
            }

        }
    }

    .right {
        .about-objective {
            padding: 3.33rem;
            display: flex;
            flex-direction: column;
            gap: 1.33rem;
        }
    }

    @media (max-width:1199px) {
        gap: 6.89rem;

        .left {
            .about-file {
                flex-direction: column;
                gap: 1rem;

                a {
                    width: fit-content;
                }
            }
        }
    }

    @media (max-width:991px) {
        gap: 4.89rem;
    }

    @media (max-width:767px) {
        gap: 3.89rem;
        padding: 0.56rem 0 3.56rem 0;
        display: flex;
        flex-direction: column;

        .left {
            .about-file {
                flex-direction: column;
                gap: 1rem;
                justify-content: flex-start;

                a {
                    width: fit-content;
                }
            }
        }
    }
}

#page-about {

    .page-section {
        padding-top: 0;
        padding-bottom: 5.56rem;

        .container {
            column-count: 2;
            column-gap: 2.67rem;

            @media (max-width:991px) {
                column-count: 1;
            }
        }
    }

    .comity-description {
        padding: 2rem 0 1rem;
    }

    #about-member {
        padding: 5.56rem 0 11.11rem;
        background-image: url(../../assets/images/hh-member.svg);
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: 20%;

        @media (max-width:767px) {
            padding: 5.56rem 0 5.56rem;
        }

        .member-team {
            margin-bottom: 2rem;
            margin-top: 3rem;

            img {
                border-radius: $rounded;
            }
        }

        .member-grid {
            padding: 2.67rem 0 0;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 5.78rem;
            row-gap: 4.28rem;

            @media (max-width:1199px) {
                column-gap: 3.78rem;
                row-gap: 2.28rem;
            }

            @media (max-width:991px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media (max-width:767px) {
                column-gap: 2.22rem;
                row-gap: 2.28rem;

                .member-function {
                    line-height: 1.3rem;
                }
            }

            @media (max-width:480px) {
                display: flex;
                flex-direction: column;
            }
        }
    }

    #about-report {
        padding: 6.67rem 0 11.11rem;

        @media (max-width:767px) {
            padding: 5.56rem 0 5.56rem;
        }

        .report-grid {
            padding: 4.06rem 0 0;
            display: flex;
            flex-direction: column;
            gap: 1.78rem;
        }
    }
}

#page-header.single-publication-header {

    display: grid;
    grid-template-columns: calc(30% - 1.835rem) calc(70% - 1.835rem);
    gap: 3.67rem;
    padding: 3.75rem 0 0;

    .left,
    .right {
        height: 100%;
    }

    .title h1 {
        font-size: clamp(2.5rem, 5.6vw, 5.6rem);
        line-height: clamp(2.5rem, 5.6vw, 5.6rem);
    }

    .img-container {
        overflow: hidden;
    }

    .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0.67rem 0 0;
        gap: 2.67rem;

        .infos {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 1.78rem;
            width: 100%;
            background-color: $color-gray-lighter;

            .publications-infos {
                display: flex;
                gap: 3.39rem;
                align-items: center;
            }
        }
    }

    .left {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            border-radius: $rounded;
            height: auto;
        }
    }

    @media (max-width:991px) {
        display: flex;
        flex-direction: column;
        gap: 1.39rem;

        .left {
            height: 45vh;
        }
    }

    @media (max-width:767px) {

        .right {
            gap: 1.78rem;

            .infos {
                flex-direction: column;
                align-items: flex-start;
                gap: 1.78rem;

                .publications-infos {
                    width: 100%;
                }
            }
        }
    }

    @media (max-width:576px) {

        .right {
            .infos {
                .publications-infos {
                    width: 100%;
                    justify-content: space-between;
                }
            }
        }
    }
}

#page-publication {
    padding: 0 0 11.11rem;

    .publication-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1.33rem;
        row-gap: 3.56rem;
    }

    @media (max-width:991px) {
        .publication-grid {
            display: flex;
            flex-direction: column;
            row-gap: 1.56rem;
        }
    }

    @media (max-width:767px) {
        padding: 0 0 5.56rem;
    }
}

#page-contact {
    padding: 0 0 11.11rem;

    @media (max-width:767px) {
        padding: 0 0 5.56rem;
    }

    .page-contact-content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 7.06rem;

        .left,
        .right,
        .street-content,
        .contact-street {
            display: flex;
            flex-direction: column;
        }

        .left {
            padding: 2.83rem 4.44rem;
            gap: 3.8rem;
        }

        h4 {
            font-family: 'Montserrat';
            font-size: 1.3rem;
            padding: 0 0 0.56rem;
        }

        .contact {
            display: flex;
            flex-direction: column;
            gap: 2.56rem;

            h6 {
                padding: 0 0 0.78rem;
            }

            .contact-content {
                display: flex;
                flex-direction: column;

                a {
                    width: fit-content;
                    margin-bottom: 0.56rem;
                }
            }
        }

        .contact-street {
            gap: 1.44rem;

            .street-container {
                display: flex;
                flex-direction: column;
                gap: 1.94rem;

                .street-content {

                    a {
                        margin: 0.56rem 0 0;
                        width: fit-content;
                    }
                }
            }
        }

        @media (max-width:1199px) {
            gap: 4.06rem;
        }

        @media (max-width:991px) {
            display: flex;
            flex-direction: column;

            .left {
                background-color: #f3f3f3;
                padding: 2.88rem 2.44rem;
                gap: 3rem;
            }
        }

        @media (max-width:390px) {
            .contact {
                .contact-content {
                    a {
                        word-break: break-all;
                        border-bottom: 0;
                    }
                }
            }
        }
    }
}

#page-radio {
    padding: 0 0 11.11rem;

    .medias-grid {
        display: flex;
        row-gap: 3.56rem;
        flex-direction: column;

        .publication-bloc {
            .publication-content {
                div {
                    color: $color-gray;
                }
            }
        }
    }

    @media (max-width:991px) {

        .medias-grid {
            row-gap: 1.56rem;

            .publication-bloc {
                .content {
                    gap: 1rem;
                }
            }
        }
    }

    @media (max-width:767px) {
        padding: 0 0 5.56rem;

        .medias-grid {
            row-gap: 1.56rem;

            .publication-bloc {
                display: flex;
                flex-direction: column;

                .left {
                    aspect-ratio: 1;
                    width: 100%;
                }
            }
        }
    }
}

#page-press {
    padding: 0 0 11.11rem;

    .medias-grid .small-container {
        display: flex;
        flex-direction: column;
        gap: 3.56rem;

        .publication-bloc {
            .publication-content {
                div {
                    color: $color-gray;
                }
            }
        }
    }

    @media (max-width:1280px) {
        .medias-grid .small-container {
            gap: 2.56rem;

            .publication-bloc {
                .content {
                    gap: 1rem;
                }
            }
        }
    }

    @media (max-width:576px) {
        padding: 0 0 5.56rem;

        .medias-grid .small-container {
            .publication-bloc {
                display: flex;
                flex-direction: column;

                .right {
                    width: 100%;
                }

                .left {
                    width: 100%;
                }
            }
        }
    }
}

#page-simple {
    padding: 0 0 11.11rem;

    ol,
    ul {
        margin: 0;
        padding: 20px;

        li {

            padding: 0.2rem;

            &::marker {
                color: $color-red;
            }
        }
    }

    .support-information {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4rem;

        h3 {
            padding-bottom: 1rem;
        }

        .support-content {
            display: flex;
            justify-content: center;
            flex-direction: column;
            gap: 1rem;
        }
    }

    .gform_heading {
        padding: 5.56rem 0 3.56rem;
        display: flex;

        h2 {
            font-size: 2.11111rem;
            line-height: 3rem;
        }

        @media (max-width:1199px) {
            h2 {
                font-size: 2.1rem;
                line-height: 1.7rem;
            }
        }

        @media (max-width:991px) {
            h2 {
                font-size: 2rem;
                line-height: 1.6rem;
            }
        }

        .gform_description,
        .gform_required_legend {
            display: none;
        }
    }

    .qr-code {
        img {
            width: 20rem;
        }
    }

    @media (max-width:767px) {
        padding: 0 0 5.56rem;
    }
}

#page-reservation {
    padding: 0 0 11.11rem;

    #concert-name {
        padding: 5.56rem 0 3.56rem;
    }

    .custom-select {
        position: relative;
        display: inline-block;
        width: calc(50% - 12.5px);
        box-shadow: 1px 1px 10px -2px rgba(119, 76, 76, 0.15);
        border: 1px solid #eee;
        border-radius: 0.5rem;
        margin-bottom: 20px;

        @media(max-width:991px){
            width: 100%;
            margin-bottom: 15px;
        }
    }

    .custom-label {
        font-size: .9rem;
        line-height: 2.2rem;
        color: #8b8b8b;
    }

    .select-selected {
        background-color: #fff;
        font-size: 16px;
        cursor: pointer;
        border-radius: .5rem;
        color: #8b8b8b;
        padding: 1.3rem;
    }

    .select-items {
        display: none;
        position: absolute;
        padding: 1.3rem;
        background-color: #fff;
        box-shadow: 1px 1px 10px -2px rgba(119, 76, 76, 0.15);
        border: 1px solid #eee;
        border-radius: 0.5rem;
        width: 100%;
        max-height: 200px;
        overflow-y: auto;
        z-index: 100;
    }

    .select-items div {
        padding: 8px;
        font-size: 16px;
        cursor: pointer;
    }

    .select-items div:hover {
        background-color: #f1f1f1;
    }


    .gform_heading {
        display: none;
    }

    @media (max-width:767px) {
        padding: 0 0 5.56rem;
    }
}

#page-order {
    padding: 0 0 11.11rem;

    #order-shipping {
        padding: 1rem 0 3.56rem;
    }

    .gform_heading {
        display: none;
    }

    @media (max-width:767px) {
        padding: 0 0 5.56rem;
    }
}

#page-video {
    padding: 0 0 11.11rem;

    .medias-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1.39rem;
        row-gap: 3.56rem;
    }

    @media (max-width:767px) {
        padding: 0 0 5.56rem;
    }

    @media (max-width:767px) {

        .medias-grid {
            display: flex;
            flex-direction: column;
            row-gap: 2.56rem;
        }

        padding: 0 0 5.56rem;
    }
}

#page-not-found {
    .error-header {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 4.56rem;
        padding-bottom: 11.11rem;

        @media (max-width:767px) {
            display: flex;
            flex-direction: column;
            gap: 3.56rem;
            padding: 0 0 5.56rem;
        }

        .left {
            display: flex;
            flex-direction: column;
            gap: 2rem;

            @media (max-width:767px) {
                gap: 1.26rem;
            }

        }

        .right {
            .error-action {
                background-color: $color-white;
                padding: 3rem;
                display: flex;
                flex-direction: column;
                gap: 2rem;

                a {
                    width: fit-content;
                }

                @media (max-width:767px) {
                    padding: 1.56rem;
                    gap: 1.26rem;
                }
            }
        }
    }
}