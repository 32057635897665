//
// Header Styles
// --------------------------------------------------

#header {
	width: 100%;
	background-color: $color-white;
	display: flex;
	align-items: center;
	height: 7.83333rem;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 200;
	transition: all 0.4s linear;

	.container {
		display: flex;
		align-items: center;
		height: 100%;
		width: 100%;
	}

	&.sticky {
		height: 6.83333rem;
		box-shadow: $box-shadow;

		#logo {
			width: 12.95rem;
			transition: all 0.4s linear;
			img {
				width: 100%;
			}
		}

		@media (max-width:1280px) {

			height: 5.83333rem;

			#logo {
				width: 8.95rem;
			}
		}

		@media (max-width:767px) {

			height: 4.83333rem;

			#logo {
				width: 6.95rem;
			}
		}

		
		@media (max-width:1280px) {
		#menu #menu-main-container ul li .sub-menu {
				top: 45px;
			}
		}

		#menu #menu-main-container ul li .sub-menu {
			top: 54px;
		}
	}

	@media (max-width:1280px) {
		height: 6.83333rem;

		#menu {
			#extra-menu {
				height: 30%;
				width: 100%;
				justify-content: end;
			}

			#menu-main-container ul li .sub-menu {
				top: 50px !important;
			}
		}
	}

	#header-content {
		display: flex;
		align-items: center;
		height: 100%;
		width: 100%;
		justify-content: space-between;
	}

	#menu-mobile-trigger {
		font-size: 31px;
		display: none;

		.trigger-bar {
			display: block;
			width: 25px;
			height: 2px;
			transition: all 0.2s linear;
			position: relative;
			background-color: $color-red;

			&:not(:first-child) {
				margin-top: 6px;
			}
		}

		@media (max-width:1199px) {
			display: block;
		}
	}

	#logo {
		float: left;
		width: 13.95rem;
		max-width: 100%;
		transition: all 0.4s linear;

		a {
			display: flex;
		}

		@media (max-width:1440px) {
			width: 11.95rem;
		}

		@media (max-width:1280px) {
			width: 10.95rem;
		}

		@media (max-width:767px) {
			width: 8.95rem;
		}
	}

	#menu {
		transition: all 0.4s linear;
		float: right;
		display: flex;
		align-items: flex-end;
		height: 100%;
		flex-direction: column;
		justify-content: center;

		@media (max-width:1199px) {
			gap: 0.5rem;
		}

		#menu-main-container {
			display: flex;
			align-items: center;
			width: 100%;
			height: 50%;

			ul {
				height: 100%;
				display: flex;
				align-items: center;
				margin: 0;
				padding: 0;
				list-style: none;
				text-align: right;
				float: right;

				li {
					height: 100%;
					display: flex;
					align-items: center;
					float: left;
					position: relative;
					margin-right: 2.17rem;

					a {
						font-size: $medium;
						display: block;
						font-weight: 400;
						color: $color-primary;

						&:hover {
							color: $color-link-hover;
						}
					}

					&:last-child {
						margin-right: 0;
					}

					&.current-menu-item,
					&.current-menu-ancestor,
					&.current-menu-parent,
					&.current-product-ancestor,
					&.current-product_cat-ancestor,
					&.current-page-ancestor {
						>a {
							color: $color-red;
						}

						.sub-menu {
							li {
								a {
									color: $color-primary;
								}
							}
						}
					}

					.sub-menu {
						display: flex;
						flex-direction: column;
						top: 60px;
						opacity: 0;
						height: 0;
						padding: 0;
						position: absolute;
						text-align: left;
						margin-left: -40px;
						transform: scale(0.95) translateY(20px);
						transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
						z-index: 10;
						overflow: hidden;
						border-radius: 0.22222rem;
						background: #FFF;
						box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.12);

						li {
							white-space: nowrap;
							margin: 0;
							float: none;
							border-bottom: 1px solid $color-gray-lighter;

							a {
								display: block;
								color: $color-primary;
								background-color: $color-white;
								padding: 20px 35px;

								&:hover {
									color: $color-link-hover;
									background-color: $color-white;
								}
							}

							&:last-child {
								margin-bottom: 0;
								border-bottom: 0px solid $color-gray-lighter;
							}

							&.current-menu-item,
							&.current-menu-ancestor,
							&.current-menu-parent,
							&.current-product-ancestor,
							&.current-product_cat-ancestor,
							&.current-page-ancestor {
								a {
									color: $color-red;
								}
							}
						}

						li.anchor-link {
							a{
								color: $color-primary;
							}

							&.current-menu-item,
							&.current-menu-ancestor,
							&.current-menu-parent,
							&.current-product-ancestor,
							&.current-product_cat-ancestor,
							&.current-page-ancestor {
								a {
									color: $color-primary;
								}
							}

							a:hover {
								color: $color-red;
							}
						}

						li.menu-file {

							&:after {
								content:"\f08e";
								font-family: 'Font Awesome 6 Regular';
								position: absolute;
								right: 10px;
								opacity: 0;
								transition: all 0.4s ease;
							}
						}

						li.menu-file {
							&:hover:after {
								opacity: 1;
								transition: all 0.4s ease;
							}
						}
					}

					&.menu-item-has-children {
						margin-right: 3.17rem;

						&:after {
							font-family: "Font Awesome 6 Pro";
							content: '\f078';
							display: block;
							position: absolute;
							top: 50%;
							right: -20px;
							font-weight: 400;
							transform: translateY(-50%);
							transform-origin: center;
							color: $color-black;
							transition: transform 0.25s;
						}

						&:hover {
							&:after {
								transform: translateY(-50%) rotate(90deg);
							}

							.sub-menu {
								height: auto;
								opacity: 1;
								transform: scale(1) translateY(0);
								padding: 0.7rem;
							}
						}
					}

					@media (max-width:1280px) {
						margin-right: 2rem;

						a {
							font-size: 1.1rem;
						}
					}
				}
			}
		}
	}

	#extra-menu {
		display: flex;
		flex-direction: row;
		height: 50%;
		align-items: flex-start;
		position: absolute;
		top: 5px;
	
		ul {
			display: flex;
			gap: 1.64rem;
			flex-direction: row;
			list-style: none;
			text-align: right;
			float: right;
			margin-top: 0px;
			margin-bottom: 0px;
			padding-inline-start: 0px;
	
			li {
				position: relative;
				color: $color-red;
				transition: all ease 0.3s;
				border-bottom: solid transparent 1px;

				&:after {
					content: '';
					position: absolute;
					width: 100%;
					transform: scaleX(0);
					border-radius: 5px;
					height: 0.05em;
					bottom: 0;
					left: 0;
					transform-origin: bottom right;
					background: $color-red-dark;
					transition: transform .25s ease-out;
				}
	
				&:before {
					padding: 0 0.6rem 0 0;
					font-family: "Font Awesome 6 Pro";
					content: "\f054";
				}
	
				&:hover {
					color: $color-red-dark;
					transition: all ease 0.3s;

					&:after {
						transform: scaleX(1);
						transform-origin: bottom left;
						transition-duration: .3s;
					}
	
					a {
						color: $color-red-dark;
						transition: all ease 0.3s;
					}
				}
			}
	
			@media (max-width:1280px) {
				a {
					font-size: 1.1rem;
				}
			}
		}
	}
}

.mm-ocd--open {

	top: 6rem;

	#menu-principal:before {
		border-top: 0;
	}

	.mm-ocd__content {
		.mm-spn.mm-spn--light {
			background: $color-white;

			li {
				&:last-of-type {
					&::after {
						border-top: 0px solid;
					}
				}

				a {
					color: $color-primary;
				}

				&.current_page_item a, 
				&.current-menu-parent a {
					color: $color-red;
				}
			}

			.menu-item-has-children {
				&::before {
					display: flex;
					justify-content: flex-end;
					align-items: center;
					font-family: "Font Awesome 6 Pro";
					content: '\f054';
					height: 100%;
					width: 100%;
					top: 0;
					right: 30px;
					z-index: 10;
					position: absolute;
					transform: rotate(0) translate(0);
					border-top: none;
					border-right: none;
				}

				a {
					&:after {
						border-right: 0px solid;
					}
				}

				.sub-menu {
					z-index: 20;

					a {
						color: $color-primary;
					}

					.current-menu-item {
						a{
							color: $color-red;
						}
					}
				}
			}
		}
		
		#menu-main-container {
			#menu-principal {
				height: auto;
				position: relative;
			}

			#extra-menu {
				height: auto;

				#menu-extra-menu {
					position: relative;
					left: 0;
					bottom: 0;
					top: auto;

					&:before {
						border-top: 0;
					}
					
					li{
						&:before {
							visibility: hidden;
						}

						a {
							color: $color-red;
						}
					}
				}
			}
		}

		@media (max-width:767px) {
			#menu-principal {
				li {
					&:last-of-type {
						&::after {
							content: "";
							display: block;
							margin-left: var(--mm-spn-item-indent);
							border-top: 1px solid;
							opacity: .15;
						}
					}
				}

			}
		}
	}
}